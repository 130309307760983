import React, { useState, useEffect,Fragment } from "react";
import VideoRecordingImage from '../../assets/svgs/video_recording.png';
import { find } from "lodash";
import CSS from "./FuneralViewPage.module.scss";
import { useTranslation } from 'react-i18next';
import { getFuneralStreams } from "../../redux/funeral/actions";
import { connect } from "react-redux";
import moment from "moment";
import validator from 'validator';
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
	postVisitorData,
} from "../../redux/funeral/actions";
import CondolenceMessagePage from "./CondolenceMessagePage";
import axios from "axios";

const NOT_STARTED_STATE = 'not_started';
const STARTING_STATE = 'starting';
const STARTED_STATE = 'started';
const STOPPING_STATE = 'stopping';
const ENDED_STATE = 'ended';


const determineStreamToShow = (streams) => {
	let chosenStream;
	chosenStream = find(streams, (stream) => {
		return stream.manuallyUploaded;
	});
	if (!chosenStream) {
		chosenStream = find(streams, (stream) => {
			return stream.state === STARTED_STATE;
		});
	}
	if (!chosenStream) {
		chosenStream = find(streams, (stream) => {
			return stream.state === STARTING_STATE;
		});
	}
	if(!chosenStream) {
		chosenStream = streams[0];
	}
	return chosenStream;
}

const generateDacastOrLivestreamIframeSource = (livestreamEvent, dacastEvent) => {
	if (livestreamEvent) {
		if (livestreamEvent.isVimeo) {
			return `https://vimeo.com/event/${livestreamEvent.eventId}/embed/interaction`;
		}

		return "https://livestream.com/accounts/29883600/events/" + livestreamEvent.eventId + "/player?width=200&height=100&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false";
	} else if (dacastEvent) {
		return "https://iframe.dacast.com/live/23278b4c-f8a8-77e8-163d-bb4d08b951b0/" + dacastEvent.eventId;
	}
	
	return "";
}

const convertDateToTimeOnly = (unformattedDate) => {
	if (unformattedDate) {
		return moment(unformattedDate).format("h:mm A");
	}
	return "";
};

const convertDateToOnlyDate = (unformattedDate) => {
	if (unformattedDate) {
		return moment(unformattedDate).format("LL");
	}
	return "";
};

const renderVideoInformation = (state, videoInformation, funeralHomeInfo, funeralDetails, watchAgain, t) => {
	console.log('videoInformation ---> ', videoInformation);
	if (videoInformation?.manuallyUploaded) {
		return (
			<div style={{backgroundColor:"black"}}>
				<video width="100%" height="945" controls>
					<source src={videoInformation.uploadedClipUrl} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</div>
		);
	}

	if (videoInformation?.livestreamEvent) {
		if (videoInformation.livestreamEvent.isVimeo) {
			return (
				<div style={{backgroundColor:"white"}}>
					<iframe 
						id="ls_embed_1641183635" 
						src={`https://vimeo.com/event/${videoInformation.livestreamEvent.eventId}/embed/interaction`}
						width="100%" 
						height="945" 
						allowFullScreen />
				</div>
			);
		}

		return (
			<div style={{backgroundColor:"black"}}>
				<iframe 
					id="ls_embed_1641183635" 
					src={`https://livestream.com/accounts/29883600/events/${videoInformation.livestreamEvent.eventId}/player?width=1680&height=945&enableInfoAndActivity=true&defaultDrawer=&autoPlay=true&mute=false`}
					width="100%" 
					height="945" 
					allowFullScreen /> 
					<script type="text/javascript" data-embed_id="ls_embed_1641183635" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>
			</div>
		);
	} else if (videoInformation?.dacastEvent) {
		return (
			<div style={{backgroundColor:"black"}}>
				<iframe 
					id="ds_embed_1641183635" 
					src={`https://iframe.dacast.com/live/23278b4c-f8a8-77e8-163d-bb4d08b951b0/${videoInformation.dacastEvent.eventId}`}
					width="100%" 
					height="945" 
					allowFullScreen /> 
					<script type="text/javascript" data-embed_id="ls_embed_1641183635" src="https://livestream.com/assets/plugins/referrer_tracking.js"></script>
			</div>
		);
	}
	
	if (videoInformation?.state === 'ended') {
		return (
			<div className={CSS.videoStreamInfo}>
				<div className={CSS.videoDetailInfo}>
					<div
						className={
							CSS.videoStreamDetailInfo
						}>
						{t("pages.deceased.ended")}{" "}
						{convertDateToOnlyDate(
							videoInformation?.streamStart
						)}{" "}
						at {""}
						{convertDateToTimeOnly(
							videoInformation?.streamEnd
						)}{" "}
						{funeralHomeInfo.timeZone}
					</div>
					<div
						className={
							CSS.videoDeceasedNameInfo
						}>
						{videoInformation?.name} <br />
						{funeralDetails.firstName}{" "}
						{funeralDetails.lastName}
					</div>
					<button
						className={`${CSS.actionBtn} ${CSS.warning}`}
						type="button"
						onClick={watchAgain}>
						{!videoInformation.livestreamEvent && !videoInformation.dacastEvent ? t("pages.deceased.expired_stream") : t("pages.deceased.watch_again")}
					</button>
				</div>
			</div>
		);
	}

	return (
		<div className={CSS.videoStreamInfo}>
			<div className={CSS.videoDetailInfo}>
				<div
					className={
						CSS.videoStreamDetailInfo
					}>
					Starting{" "}
					{convertDateToOnlyDate(
						videoInformation?.streamStart
					)}{" "}
					at {""}
					{convertDateToTimeOnly(
						videoInformation?.streamStart
					)}{" "}
					{funeralHomeInfo.timeZone}
				</div>
				<div
					className={
						CSS.videoDeceasedNameInfo
					}>
					{videoInformation?.name} <br />
					{funeralDetails.firstName}{" "}
					{funeralDetails.lastName}
				</div>
			</div>
		</div>
	);
}

function VideoStreamLiveStream({ funeralDetails, funeralStreams, funeralClips, funeralHomeInfo, funeralId, postVisitorData, getFuneralStreams, funeralComments, visitorDetails }) {
	const [errorStates, setErrorStates] = useState({
		userEmail: "",
	});
	const [funeralPassword, setFuneralPassword] = useState("");
	const [canAccessVideo, setCanAccessVideo] = useState(false);
	const [userFullName, setUserFullName] = useState(()=>{
		const savedName = localStorage.getItem("fullName");
		const initialName = JSON.parse(savedName);
		return initialName || "";
	});
	const [userEmail, setUserEmail] = useState(()=>{
		const savedEmail = localStorage.getItem("email");
		const initialEmail = JSON.parse(savedEmail);
		return initialEmail || "";
	});
	const [userSavedData, setUserData] = useState({
		email: "",
		fullName: "",
		password: "",
		isAuthenticated: false,
	});
	const [dropdownVideoOpen, setVideoDropdown] = useState(false);
	const toggleVideosDropdown = () => setVideoDropdown(!dropdownVideoOpen);
	const [passwordFlag, setPasswordFlag] = useState(false);
	const [accessStreamChecker, setAccessStreamChecker] = useState(false);
	const [emailPreference, setEmailPreference] = useState(null);
	useEffect(() => {
		getFuneralStreams(funeralId, true);
	}, []);
	useEffect(() => {
		checkDetailsValid();
	}, [funeralPassword, errorStates, userEmail, userFullName]);
	useEffect(() => {
	}, [accessStreamChecker]);
	useEffect(() => {
	}, [passwordFlag]);
	const getBaseUrl = () => {
		if (process.env.NODE_ENV === 'development') {
		  return "http://localhost:4000";
		}
	  
		return "https://api.funeralliveapp.ca";
	  }
	const checkDetailsValid = () => {
		if(funeralDetails.passwordDigest){
			if (
				userEmail &&
				userEmail.trim() &&
				userFullName &&
				userFullName.trim() &&
				funeralPassword &&
				!errorStates.userEmail
			) {
				setAccessStreamChecker(true);
				return true;
			}
			else{
				setAccessStreamChecker(false);
				return false;
			}
		}
		else{
			if (
				userEmail &&
				userEmail.trim() &&
				userFullName &&
				userFullName.trim() &&
				!errorStates.userEmail
			) {
				setAccessStreamChecker(true);
				return true;
			}
			else{
				setAccessStreamChecker(false);
				return false;
			}
		}	
	}
	useEffect(() => {
		if(validator.isEmail(userEmail) || !userEmail){
			setErrorStates(prev=>{
				return {
					...prev,
					userEmail:""
				}
			})
		}else{
			setErrorStates(prev=>{
				return {
					...prev,
					userEmail:t("pages.create_event.email_validation")
				}
			})
		}
	}, [userEmail]);
	useEffect(() => {
		if (funeralStreams.length > 0 || funeralClips.length > 0) {
			const videoToShow = determineStreamToShow([...funeralClips, ...funeralStreams]);
			setVideoInformation(videoToShow);
			if (videoToShow.clips && videoToShow.clips[0]?.isLive) {
				setstate(false);
			} else {
				setstate(true);
			}
		}
	}, [funeralStreams]);
	const [videoInformation, setVideoInformation] = useState(null);
	const [state, setstate] = useState(false);
	const saveCurrentUserDetails = (shouldByPass = false) => {
		if (shouldByPass) {
			axios.get(`api/funerals/${funeralId}/validate_password?password=${funeralPassword}`, {
				baseURL: getBaseUrl(),
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					"Authorization": `Bearer ${process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc'}`
				}
				})
				.then(function (response) {
					passwordResponse=response;
					if(passwordResponse.data.valid_password){
						setPasswordFlag(false);
						setCanAccessVideo(true);
					}
					else{
						setPasswordFlag(true);
					}
				});
		} else if (funeralDetails.passwordDigest) {
			var passwordResponse = {};
			if(userEmail &&
				userEmail.trim() &&
				userFullName &&
				userFullName.trim() &&
				funeralPassword &&
				!errorStates.userEmail){
				axios.get(`api/funerals/${funeralId}/validate_password?password=${funeralPassword}`, {
					baseURL: getBaseUrl(),
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						"Authorization": `Bearer ${process.env.REACT_APP_DECEASED_PAGE_TOKEN || 'abc'}`
					}
				  })
				  .then(function (response) {
					passwordResponse=response;
					if(passwordResponse.data.valid_password){
						setUserData((prev) => {
							return {
								...prev,
								email: userEmail,
								fullName: userFullName,
								isAuthenticated: true,
							};
						});
						const visitorPayload = {
							visitor: {
								name: userFullName,
								email: userEmail,
								email_opt_in: emailPreference
							}
						}
						postVisitorData(funeralId, visitorPayload);
						localStorage.setItem("fullName", JSON.stringify(userFullName));
						localStorage.setItem("email", JSON.stringify(userEmail));
						setPasswordFlag(false);
					}
					else{
						setPasswordFlag(true);
					}
				  })
			}
		}
		else{
			if(userEmail &&
				userEmail.trim() &&
				userFullName &&
				userFullName.trim() &&
				!errorStates.userEmail){
					setUserData((prev) => {
						return {
							...prev,
							email: userEmail,
							fullName: userFullName,
							isAuthenticated: true,
						};
					});
					const visitorPayload = {
						visitor: {
							name: userFullName,
							email: userEmail,
							email_opt_in: emailPreference
						}
					}
					postVisitorData(funeralId, visitorPayload);
					localStorage.setItem("fullName", JSON.stringify(userFullName));
					localStorage.setItem("email", JSON.stringify(userEmail));
			}
		}
	};

	const openClipData = (stream) => {
    setVideoInformation(null); // Temporarily set to null to force re-render
    setTimeout(() => {
        setVideoInformation(stream); // Update with the actual stream after a brief delay
    }, 0);
    setstate(true);
	};

  
	const watchAgain = () =>{
		setstate(false);
	}
	const { t } = useTranslation();
	const bypassGuestLoginButHasPassword = funeralDetails.bypassGuestLogin && funeralDetails.passwordDigest;

	const isFormValid = () => {
		if (bypassGuestLoginButHasPassword) {
			return funeralPassword !== '' && emailPreference !== null;
		}
		return accessStreamChecker && emailPreference !== null;
	};

	return (
        <>
		<section className={CSS.userForm}>
			{(!funeralDetails.bypassGuestLogin || funeralDetails.passwordDigest) &&
			<div className={CSS.userContainer}>
				<div className={CSS.sectionContainer}>
					{(userSavedData.isAuthenticated || canAccessVideo) || (
						<div className={CSS.formWrappper}>
							<div className={CSS.innerWrappper}>
								{!bypassGuestLoginButHasPassword &&
									<span className={CSS.formHeading}>
										{t("pages.deceased.stream_access_title")}
									</span>
								}
								<div className={CSS.formElements}>
									<div className={CSS.inputWrapperRow}>
										{!bypassGuestLoginButHasPassword &&
											<div className={CSS.inputWrapper}>
												<label className={CSS.inputHeader}>
													{t(
														"pages.deceased.stream_access_name"
													)}
												</label>
												<input
													className={CSS.inputElement}
													type="text"
													value={userFullName}
													onChange={(e) =>
														setUserFullName(
															e.target.value
														)
													}></input>
											</div>
										}
										{!bypassGuestLoginButHasPassword &&
										<div className={CSS.inputWrapper}>
											<label className={CSS.inputHeader}>
												{t(
													"pages.deceased.stream_access_email"
												)}
											</label>
											<input
												className={
													errorStates.userEmail
														? CSS.inputError
														: CSS.inputElement
												}
												type="text"
												value={userEmail}
												onChange={(e) =>
													setUserEmail(e.target.value)
												}></input>
											<div className={CSS.errorText}>
												{errorStates.userEmail}
											</div>
										</div>
										}
									</div>
									{funeralDetails.passwordDigest ?
									<div className={CSS.inputWrapperRow}>
										<div className={CSS.inputWrapper}>
											<label className={CSS.inputHeader}>
												{t(
													"pages.deceased.stream_access_password"
												)}
											</label>
											<input
												className={passwordFlag ? CSS.inputError : CSS.inputElement}
												type="password"
												value={funeralPassword}
												placeholder="Enter password"
												onChange={(e) =>
													setFuneralPassword(e.target.value)
												}></input>
											{passwordFlag ? 
												<div className={CSS.errorText}>
													{t("pages.deceased.incorrect_password")}
												</div>: ""}
										</div>
									</div>: ""}
								</div>
								<div className={CSS.emailPreferenceSection}>
									<h4 className={CSS.emailPreferenceTitle}>
										{t("pages.deceased.stay_connected")}
									</h4>
									<p className={CSS.emailPreferenceText}>
										{t("pages.deceased.email_preference_description")}
									</p>
									<div className={CSS.emailPreferenceOptions}>
										<label className={CSS.radioLabel}>
											<input
												type="radio"
												name="emailPreference"
												checked={emailPreference === true}
												onChange={() => setEmailPreference(true)}
											/>
											{t("pages.deceased.email_preference_yes", { funeralHomeName: funeralHomeInfo.name })}
										</label>
										<label className={CSS.radioLabel}>
											<input
												type="radio"
												name="emailPreference"
												checked={emailPreference === false}
												onChange={() => setEmailPreference(false)}
											/>
											{t("pages.deceased.email_preference_no")}
										</label>
									</div>
								</div>
								<div className={CSS.submitWrapper}>
									<div className={CSS.hintMsg}></div>
									<button 
										className={`${CSS.actionBtn} ${CSS.warning} ${
											isFormValid() ? "" : CSS.disabled
										}`}
										type="button"
										onClick={() => saveCurrentUserDetails(bypassGuestLoginButHasPassword)}
									>
										{t("pages.deceased.access_stream")}
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>}
			{(canAccessVideo || userSavedData.isAuthenticated || (funeralDetails.bypassGuestLogin && !funeralDetails.passwordDigest)) && (funeralStreams.length > 0 || funeralClips.length > 0) && (
				<Fragment>
					<div className={CSS.userform}>
						<div className={CSS.dropdownBtn}>
							<div className={CSS.dropdownHeader}>
								<span className={CSS.burgerMenuTitle}>
									{funeralStreams.length + funeralClips.length} {t("pages.deceased.streams")}
								</span>
								{dropdownVideoOpen ? (
									<ExpandLessIcon
										className={CSS.dropdownIcon}
										onClick={toggleVideosDropdown}
									/>
								) : (
									<ExpandMoreOutlinedIcon
										className={CSS.dropdownIcon}
										onClick={toggleVideosDropdown}
									/>
								)}
							</div>
							<div className={CSS.menu}>
								{dropdownVideoOpen &&
										funeralDetails &&
										[...funeralClips, ...funeralStreams].map((stream) => 
										<div
											key={stream.id}
											className={CSS.dropdownMenuItems}
											onClick={() => openClipData(stream)}
										>
											{(stream.livestreamEvent || stream.dacastEvent) && (
												<div className={CSS.otherClips}>
													<div className={CSS.videoFrame}>
														<iframe id="ls_embed_1641182855" 
															src={generateDacastOrLivestreamIframeSource(stream.livestreamEvent, stream.dacastEvent)}
															width="200" 
															height="100"
														/>
													</div>
													<div className={CSS.streamName}>
														{stream.name} <br />
														{
															funeralDetails.firstName
														}{" "}
														{
															funeralDetails.lastName
														}
													</div>
													{stream.state === ENDED_STATE &&
														<div
															className={CSS.endTime}>
															{t("pages.deceased.ended")}{" "}
															{convertDateToOnlyDate(
																stream.streamEnd
															)}{" "}
															at <br />
															{convertDateToTimeOnly(
																stream.streamEnd
															)}{" "}
															{
																funeralHomeInfo.timeZone
															}
														</div>
													}
													{stream.state === STARTED_STATE &&
														<div
															className={CSS.endTime}>
															Started{" "}
															{convertDateToOnlyDate(
																stream.streamStart
															)}{" "}
															at <br />
															{convertDateToTimeOnly(
																stream.streamStart
															)}{" "}
															{
																funeralHomeInfo.timeZone
															}
														</div>
													}
													{stream.state === NOT_STARTED_STATE &&
														<div
															className={CSS.endTime}>
															Starting{" "}
															{convertDateToOnlyDate(
																stream.streamStart
															)}{" "}
															at <br />
															{convertDateToTimeOnly(
																stream.streamStart
															)}{" "}
															{
																funeralHomeInfo.timeZone
															}
														</div>
													}
												</div>
											)}
											{!(stream.livestreamEvent || stream.dacastEvent) && stream.manuallyUploaded && (
												<div
													className={CSS.otherClips}
													style={{
														display: "flex",
														alignItems: "center",
														width: "100%",
														cursor: "pointer",
														padding: "10px",
														boxSizing: "border-box",
													}}
												>
													{/* Text Section */}
													<div
														className={CSS.streamText}
														style={{
															flex: "1",
															textAlign: "left",
															paddingRight: "10px", // Adds spacing between text and image
														}}
													>
														<div className={CSS.streamName} style={{ fontWeight: "bold" }}>
															{stream.localRecording ? `${stream.name} Local Recording on ${convertDateToOnlyDate(stream.createdAt)}` : `${stream.name} Uploaded Clip on ${convertDateToOnlyDate(stream.createdAt)}`}
														</div>
														<div className={CSS.streamDetails}>
															{funeralDetails.firstName} {funeralDetails.lastName}
														</div>
													</div>

													{/* Image Section */}
													<div
														className={CSS.videoFrame}
														style={{
															flex: "1",
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<img
															src={VideoRecordingImage}
															alt="Stream preview"
															style={{
																width: "100%",
																height: "auto",
																maxWidth: "200px",
																objectFit: "cover",
																borderRadius: "8px",
															}}
														/>
													</div>
												</div>
											)}
											{!(stream.livestreamEvent || stream.dacastEvent) && !stream.manuallyUploaded && (
												<div className={CSS.streamNotStartedDropdownItem}>
													<div className={CSS.streamName}>
														{stream.name} •{" "}
														{
															funeralDetails.firstName
														}{" "}
														{
															funeralDetails.lastName
														}
													</div>
													<div
														className={CSS.endTime}>
														{t("pages.deceased.starts")}{" "}
														{convertDateToOnlyDate(
															stream.streamStart
														)}{" "}
														at <br />
														{convertDateToTimeOnly(
															stream.streamStart
														)}{" "}
														{
															funeralHomeInfo.timeZone
														}
													</div>
												</div>
											)}
										</div>)
								}
							</div>
						</div>
					</div>
					{renderVideoInformation(state, videoInformation, funeralHomeInfo, funeralDetails, watchAgain, t)}
				</Fragment>
			)}
		</section>
		{funeralDetails.bypassGuestLogin &&
		<section className={CSS.commentsLoginWraper}>
			<div className={CSS.userContainer}>
				<div className={CSS.sectionContainer}>
					{userSavedData.isAuthenticated || (
						<div className={CSS.formWrappper}>
							<div className={CSS.innerWrappper}>
								<span className={CSS.formHeading}>
									{t("pages.deceased.stream_access_title")}
								</span>
								<div className={CSS.formElements}>
									<div className={CSS.inputWrapperRow}>
										<div className={CSS.inputWrapper}>
											<label className={CSS.inputHeader}>
												{t("pages.deceased.stream_access_name")}
											</label>
											<input
												className={CSS.inputElement}
												type="text"
												value={userFullName}
												onChange={(e) =>
													setUserFullName(e.target.value)
												}></input>
										</div>
										<div className={CSS.inputWrapper}>
											<label className={CSS.inputHeader}>
												{t("pages.deceased.stream_access_email")}
											</label>
											<input
												className={
													errorStates.userEmail
														? CSS.inputError
														: CSS.inputElement
												}
												type="text"
												value={userEmail}
												onChange={(e) =>
													setUserEmail(e.target.value)
												}></input>
											<div className={CSS.errorText}>
												{errorStates.userEmail}
											</div>
										</div>
									</div>
									{funeralDetails.passwordDigest ?
									<div className={CSS.inputWrapperRow}>
										<div className={CSS.inputWrapper}>
											<label className={CSS.inputHeader}>
												{t("pages.deceased.stream_access_password")}
											</label>
											<input
												className={passwordFlag ? CSS.inputError : CSS.inputElement}
												type="password"
												value={funeralPassword}
												placeholder="Enter password"
												onChange={(e) =>
													setFuneralPassword(e.target.value)
												}></input>
											{passwordFlag ? 
												<div className={CSS.errorText}>
													{t("pages.deceased.incorrect_password")}
												</div>: ""}
										</div>
									</div>: ""}
								</div>
								<div className={CSS.emailPreferenceSection}>
									<h4 className={CSS.emailPreferenceTitle}>
										{t("pages.deceased.stay_connected")}
									</h4>
									<p className={CSS.emailPreferenceText}>
										{t("pages.deceased.email_preference_description")}
									</p>
									<div className={CSS.emailPreferenceOptions}>
										<label className={CSS.radioLabel}>
											<input
												type="radio"
												name="emailPreference"
												checked={emailPreference === true}
												onChange={() => setEmailPreference(true)}
											/>
											{t("pages.deceased.email_preference_yes", { funeralHomeName: funeralHomeInfo.name })}
										</label>
										<label className={CSS.radioLabel}>
											<input
												type="radio"
												name="emailPreference"
												checked={emailPreference === false}
												onChange={() => setEmailPreference(false)}
											/>
											{t("pages.deceased.email_preference_no")}
										</label>
									</div>
								</div>
								<div className={CSS.submitWrapper}>
									<div className={CSS.hintMsg}></div>
									<button 
										className={`${CSS.actionBtn} ${CSS.warning} ${
											accessStreamChecker && emailPreference !== null ? "" : CSS.disabled
										}`}
										type="button"
										onClick={() => saveCurrentUserDetails(false)}>
										{t("pages.deceased.access_comments")}
									</button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</section>}
		{funeralComments && <CondolenceMessagePage showLogin={funeralDetails.bypassGuestLogin}  funeralComments={funeralComments} visitorDetails={visitorDetails} funeralId={funeralId} userSavedData={userSavedData}/>}
		</>
	);
}
const mapStateToProps = (state) => {
	const funeralClips = state.funeral.funeralClips || [];
	const funeralStreams = state.funeral.funeralStreams || [];

	const processedStreams = funeralStreams.filter(stream => 
		!funeralClips.some(clip => 
			clip.streamId === stream.id && clip.localRecording
		)
	);

	const processedClips = funeralClips.map(clip => {
		const correspondingStream = funeralStreams.find(stream => stream.id === clip.streamId);
		return {
			...clip,
			name: correspondingStream?.name || 'Unnamed Recording',
		};
	});

	return {
		funeralStreams: processedStreams,
		funeralClips: processedClips,
	};
};

const mapDispatchToProps = {
	getFuneralStreams,
	postVisitorData,
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoStreamLiveStream);
